import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import { Col, Row, Container, Button } from "react-bootstrap";

function ContactForm() {
	const [state, handleSubmit] = useForm("xkndpjyn");
	if (state.succeeded) {
		return (
			<div>
				<Form className="w-100" onSubmit={handleSubmit}>
					<Row className="g-3 mb-3">
						<Col md={6}>
							<Form.Group controlId="firstName">
								<Form.Control
									className="p-3"
									name="firstName"
									placeholder="First Name"
									type="text"
								/>
							</Form.Group>
							<ValidationError
								prefix="First Name"
								field="firstName"
								errors={state.errors}
							/>
						</Col>
						<Col md={6}>
							<Form.Group controlId="lastName">
								<Form.Control
									className="p-3"
									name="lastName"
									placeholder="Last Name"
									type="text"
								/>
							</Form.Group>
							<ValidationError
								prefix="Last Name"
								field="lastName"
								errors={state.errors}
							/>
						</Col>
					</Row>
					<Row className="g-3 mb-3">
						<Col md={6}>
							<Form.Group controlId="email">
								<Form.Control
									className="p-3"
									name="email"
									type="email"
									placeholder="Your Email"
								/>
							</Form.Group>
							<ValidationError
								prefix="Email"
								field="email"
								errors={state.errors}
							/>
						</Col>
						<Col md={6}>
							<Form.Group controlId="phone">
								<Form.Control
									className="p-3"
									type="phone"
									name="phone"
									placeholder="Your Phone"
								/>
							</Form.Group>

							<ValidationError
								prefix="Phone"
								field="phone"
								errors={state.errors}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="questions">
								<Form.Control
									className="p-3"
									name="questions"
									placeholder="Your Message"
									as="textarea"
									rows={3}
								/>
							</Form.Group>

							<ValidationError
								prefix="Questions"
								field="questions"
								errors={state.errors}
							/>
						</Col>
					</Row>

					{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
					<Button
						className="btn  w-100 "
						type="submit"
						variant="outline-primary py-3"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						Send Message
					</Button>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Row className="g-3 mb-3">
				<Col md={6}>
					<Form.Group controlId="firstName">
						<Form.Control
							className="p-3"
							name="firstName"
							placeholder="First Name"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="First Name"
						field="firstName"
						errors={state.errors}
					/>
				</Col>
				<Col md={6}>
					<Form.Group controlId="lastName">
						<Form.Control
							className="p-3"
							name="lastName"
							placeholder="Last Name"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="Last Name"
						field="lastName"
						errors={state.errors}
					/>
				</Col>
			</Row>
			<Row className="g-3 mb-3">
				<Col md={6}>
					<Form.Group controlId="email">
						<Form.Control
							className="p-3"
							name="email"
							type="email"
							placeholder="Your Email"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />
				</Col>
				<Col md={6}>
					<Form.Group controlId="phone">
						<Form.Control
							className="p-3"
							type="phone"
							name="phone"
							placeholder="Your Phone"
						/>
					</Form.Group>

					<ValidationError prefix="Phone" field="phone" errors={state.errors} />
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3" controlId="questions">
						<Form.Control
							name="questions"
							className="p-3"
							placeholder="Your Questions"
							as="textarea"
							rows={3}
						/>
					</Form.Group>

					<ValidationError
						prefix="Questions"
						field="questions"
						errors={state.errors}
					/>
				</Col>
			</Row>

			{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
			<Button
				className="btn  w-100 "
				type="submit"
				variant="outline-primary py-3"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Send Questions
			</Button>
		</Form>
	);
}
export default ContactForm;
