import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef } from "react";
import Layout from "../components/layout";
import { BgImage } from "gbimage-bridge";
import { Helmet } from "react-helmet";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Col, Row, Container, Button } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import ContactForm from "../components/contactForm";
import { BsTelephone } from "react-icons/bs";
import Wave from "../images/wave.svg";

const ContactPage = () => {
	const tl = useRef();
	const {
		wpContactUs: { ContactUsFields, seoFields },
		site,
	} = useStaticQuery(graphql`
		query {
			wpContactUs {
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					image {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				ContactUsFields {
					contactDetials {
						link
						text
						title
						icon {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					formSectionTitle
					heroSection {
						title
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const { contactDetials, formSectionTitle, heroSection } = ContactUsFields;

	const siteUrl = site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact",
				item: {
					url: `${siteUrl}/contact`,
					id: `${siteUrl}/contact`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section
						className="position-relative"
						style={{ background: "#292429b3" }}
					>
						<Container className="py-8 py-lg-10">
							<Row>
								<Col>
									<h1 className=" display-1 Alex-Brush  text-center text-white">
										{heroSection.title}
									</h1>
								</Col>
							</Row>
						</Container>
						<img
							src={Wave}
							alt=""
							style={{
								width: "100%",
								bottom: "-4px",
							}}
							className="position-absolute  start-0"
						/>
					</section>
					<section className="pt-5 pt-lg-7">
						<Container>
							<Row className="justify-content-center">
								<Col lg={8}>
									<Row className="justify-content-center g-4 align-items-center">
										{contactDetials.map((service, i) => (
											<Col
												style={{ minHeight: "100%" }}
												className="text-center  "
												md={6}
											>
												<div className="position-relative h-100">
													<GatsbyImage
														className=" w-100 "
														style={{ maxWidth: "40px" }}
														image={
															service.icon.localFile.childImageSharp
																.gatsbyImageData
														}
														alt={service.icon.altText}
													/>
													<h3 className="fs-3 mt-3 ">{service.title}</h3>
													<a
														className="text-lowercase primary-link"
														href={service.link}
													>
														{service.text}
													</a>
												</div>
											</Col>
										))}
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row className="justify-content-center">
								<Col lg={8}>
									<h2 className="mb-4 text-center Garamond-Med display-5">
										{formSectionTitle}
									</h2>
									<ContactForm />
								</Col>
							</Row>
						</Container>
					</section>
					<section>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.945656257713!2d55.1416692753776!3d25.069830977793245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d7705acd969%3A0x6109f50b4078d130!2sWellness%20We%20Need%20(Personal%20Sport%20Coaching%20Services)!5e0!3m2!1sen!2suk!4v1712585610346!5m2!1sen!2suk"
							width="100%"
							height="400"
							style="border:0;"
							allowfullscreen=""
							loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"
						></iframe>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default ContactPage;
